import { autoinject, PLATFORM } from 'aurelia-framework';
import { BaseViewModel } from '../../../common';
import { getLogger } from 'aurelia-logging';
import { RouterConfiguration } from 'aurelia-router';

@autoinject()
export class SiteDetailsGallery extends BaseViewModel<void> {
  constructor() {
    super(getLogger('SiteDetailsGallery'));
  }

  configureRouter(config: RouterConfiguration) {
    config.map([
      {
        route: '',
        name: 'sitedetails-gallerylist',
        moduleId: PLATFORM.moduleName(
          './modules/sitedetailsgallerylist/sitedetailsgallerylist',
          'sitedetails-gallerylist'
        )
      },
      {
        route: ':galleryItemId',
        name: 'sitedetails-gallerydetails',
        moduleId: PLATFORM.moduleName(
          './modules/sitedetailsgallerydetails/sitedetailsgallerydetails',
          'sitedetails-gallerydetails'
        )
      }
    ]);
  }
}
